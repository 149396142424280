import React from 'react'

import moment from 'moment'

import { useApp } from '@wap-client/core'

import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor/Anchor'

import FestivalDate from '../festival-date'
import FestivalLocation from '../festival-location'

import { IFestivalCard } from './types'

const FestivalCard: React.FunctionComponent<IFestivalCard> = ({ festival }) => {
  const { anchor, city, endDate, id, image, startDate } = festival

  const date = moment().format('YYYY-MM-DD[T]00:00:00')
  const app = useApp()

  const renderBadge = (date: string, startDate: string, endDate: string) => {
    if (date >= startDate && date <= endDate) {
      return (
        <div className="festival-card__detail__badge festival-card__detail__badge--active">
          {app.settings.translations['activeFestival']}
        </div>
      )
    } else if (date >= startDate) {
      return (
        <div className="festival-card__detail__badge festival-card__detail__badge--previous">
          {app.settings.translations['previousFestival']}
        </div>
      )
    } else if (date < startDate) {
      return (
        <div className="festival-card__detail__badge festival-card__detail__badge--future">
          {app.settings.translations['futureFestival']}
        </div>
      )
    }
  }

  const addAnchorFestivalId = festival?.id
    ? {
        ...anchor,
        href: `${anchor?.href}?festivalId=${festival?.id}`,
      }
    : { ...anchor }

  return (
    <div className="festival-card">
      <figure>
        <Anchor {...addAnchorFestivalId}>
          <Image {...image} alt={`${city}  ${festival}`} />
        </Anchor>
      </figure>
      <div className="festival-card__detail">
        <FestivalLocation festival={id} date={false} />
        <div className="festival-card__detail__date">
          <FestivalDate startDate={startDate} endDate={endDate} version="v1" />
        </div>

        {renderBadge(date, startDate, endDate)}
      </div>
    </div>
  )
}

export default FestivalCard
