import React, { useCallback, useEffect, useState } from 'react'

import { queryService } from '@wap-client/services'
import { useApp } from '@wap-client/core'

import FestivalDate from '../festival-date'

import { FestivalLocationProps } from './types'
import { Festival } from '../festivals/types'
import Anchor from '@/components/base/anchor/Anchor'

const FestivalLocation: React.FunctionComponent<FestivalLocationProps> = ({
  festival,
  date = true,
}) => {
  const app = useApp()

  const [data, setData] = useState<Festival[]>()

  const getFestival = useCallback(async (query?: string, language?: any) => {
    try {
      const response = await queryService.run<Festival[]>(
        app.environment,
        {
          name: 'kty-festivaller-box-veri-sorgusu',
          language: language,
          query: query,
        },
        [
          {
            name: 'id',
            path: 'id',
            type: 'static',
          },
          {
            name: 'festival',
            path: 'festivalIsmi',
            type: 'static',
          },
          {
            name: 'city',
            path: 'sehir',
            type: 'static',
          },
          {
            name: 'startDate',
            path: 'baslangicTarihi',
            type: 'static',
          },
          {
            name: 'endDate',
            path: 'bitisTarihi',
            type: 'static',
          },
          {
            name: 'color',
            path: 'renk',
            type: 'static',
          },
          {
            name: 'anchor',
            path: 'festivalLink',
            type: 'static',
          },
        ]
      )

      if (response) {
        setData(response.data)
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    if (festival) {
      getFestival(`id=${festival}`, app.language)
    }
  }, [app, app.params, app.language])

  return (
    <div className="festival-location">
      {data?.map((festival, index) => (
        <Anchor
          href={`${festival.anchor && festival.anchor.href}?festivalId=${
            festival.id
          }`}
          key={index}
        >
          {festival.festival && (
            <div
              className="festival-location__festival"
              dangerouslySetInnerHTML={{ __html: festival.festival }}
            />
          )}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              className="festival-location__city"
              style={{ backgroundColor: festival.color }}
              dangerouslySetInnerHTML={{ __html: festival.city }}
            />
            {festival.startDate && festival.endDate && date && (
              <div className="festival-location__date">
                <FestivalDate
                  startDate={festival.startDate}
                  endDate={festival.endDate}
                  version="v2"
                />
              </div>
            )}
          </div>
        </Anchor>
      ))}
    </div>
  )
}

export default FestivalLocation
